<template>
  <a :id="id" href="javascript:;" class="text-primary font-bold" :data-clipboard-text="text">
    <slot></slot>
  </a>
</template>

<script>
import { defineComponent } from 'vue'
import ClipboardJS from 'clipboard'
import { toastController } from '@ionic/vue'

export default defineComponent({
  props: {
    text: {
      required: true,
      type: String,
    },

    copied: {
      required: false,
      type: String,
      default: 'Скопировано!',
    },

    duration: {
      required: false,
      type: Number,
      default: 2000,
    },
  },

  mounted() {
    setTimeout(() => this.createClipboardButton(), 200)
  },

  methods: {
    createClipboardButton() {
      const clipboard = new ClipboardJS(document.getElementById(this.id))
      clipboard.on('success', () => this.onTextCopied())
    },

    async onTextCopied() {
      const toast = await toastController.create({
        message: this.copied,
        duration: this.duration,
        color: 'primary',
        cssClass: 'text-white',
      })

      await toast.present()
    },
  },

  data() {
    return {
      id: `clipboard-button-${Math.random().toString().replace('.', '')}`,
    }
  }
})
</script>
